<template>
  <div class="content-layout-fixer">
    <div class="content-card-list">
      <div class="menu-wrapper">
        <div class="title-wrapper">
          <VueText style="margin-top: 60px" sizeLevel="11" weightLevel="3">{{ title }}</VueText>
          <div class="title-wrapper-inner">
            <div v-if="hasSearch && showSearch" class="input-wrapper" ref="inputWrapper">
              <input
                @keyup.enter="submitSearch"
                id="funSearch"
                name="funSearch"
                type="search"
                placeholder="İçerik Ara"
                v-model="searchContent"
              />
              <span @click="submitSearch" class="search-icon">
                <VueIcon
                  @click="searchToggle"
                  :iconName="icons.search.name"
                  :width="icons.search.width"
                  :height="icons.search.height"
                  :iconColor="'#79838e'"
                ></VueIcon>
              </span>
            </div>
            <div class="icon-wrapper">
              <div
                v-if="hasSearch && !showSearch"
                class="icon-button search-button"
                @click="searchToggle"
              >
                <VueIcon
                  :iconName="icons.search.name"
                  :width="icons.search.width"
                  :height="icons.search.height"
                  :iconColor="'#79838e'"
                ></VueIcon>
              </div>
              <div v-if="hasFilter" class="icon-button filter-button" @click="filterClicked">
                <VueIcon
                  :iconName="icons.filter.name"
                  :width="icons.filter.width"
                  :height="icons.filter.height"
                  :iconColor="'#79838e'"
                ></VueIcon>
              </div>
            </div>
          </div>
        </div>
        <slot></slot>
      </div>
      <ul v-if="contents.length" class="list-container">
        <li
          v-for="(content, index) in contents"
          class="content-card"
          :key="`feed_${content.feedId}_${index}`"
        >
          <BrandContentItem
            :model="content"
            :index="index"
            @contentClick="contentClicked(content, index)"
            :eventTitle="eventTitle"
            :listedUnder="title"
          ></BrandContentItem>
        </li>
      </ul>
      <button
        v-if="showButton && contents.length"
        class="btn-more"
        @click="showMoreClicked(title, id)"
      >
        <VueText sizeLevel="4" weightLevel="4">Daha Fazla Gör</VueText>
      </button>
    </div>
  </div>
</template>
<script>
import BrandContentItem from '@/components/brand/BrandContentItem/BrandContentItem.vue';
import StorageHelper from '@/utils/storageHelper';
import VueText from '@/components/shared/VueText/VueText.vue';
import VueIcon from '@/components/shared/VueIcon/VueIcon.vue';
import { ICON_VARIABLES } from '@/constants/component.constants';
import { mapGetters } from 'vuex';
import gtmUtils from '@/mixins/gtmUtils.js';
export default {
  name: 'BrandContentList',
  components: {
    BrandContentItem,
    VueText,
    VueIcon,
  },
  mixins: [gtmUtils],
  props: {
    contents: {
      type: Array,
    },
    title: {
      type: String,
    },
    id: {
      type: [Number, String],
      default: '',
    },
    showButton: {
      type: Boolean,
    },
    hasFilter: {
      type: Boolean,
      default: false,
    },
    hasSearch: {
      type: Boolean,
      default: false,
    },
    eventTitle: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      searchContent: '',
      showSearch: false,
    };
  },
  created() {
    window.addEventListener('click', e => {
      if (this.$refs.inputWrapper) {
        if (
          this.showSearch &&
          !e.target.classList.contains('search-button') &&
          !this.$refs.inputWrapper.contains(e.target) &&
          this.searchContent === ''
        ) {
          this.showSearch = false;
          this.searchContent = '';
          this.submitSearch();
        }
      }
    });
  },
  methods: {
    contentClicked(feed, index) {
      this.pushDataLayerEvent('contentClicked', {
        ...feed,
        pageName: this.eventTitle,
        index: index + 1,
      });
    },
    showMoreClicked(title, id) {
      this.$emit('showMoreClicked', title, id);
      this.submitSearch();
    },
    filterClicked() {
      this.$emit('filterClicked');
    },
    searchToggle() {
      this.showSearch = !this.showSearch;
    },
    submitSearch() {
      let searchedText = this.searchContent;
      this.$store.dispatch('app/setSearchedText', searchedText);
    },
  },
  computed: {
    ...mapGetters('app', ['getSearchedText']),
    icons() {
      return ICON_VARIABLES;
    },
  },
  beforeMount() {
    const storageUser = new StorageHelper({ id: process.env.VUE_APP_STORAGE_CURRENT_USER_KEY });
    let name = '';
    if (storageUser) {
      name = storageUser.get().name;
    }
    this.$route.meta.title = this.$route.meta.title + name;
  },
  watch: {
    getSearchedText: {
      handler(newVal, oldVal) {
        if (newVal != oldVal) {
          this.searchContent = newVal;
        }
      },
    },
    eventTitle: function(newTitle, oldTitle) {
      if (newTitle !== oldTitle) {
        this.pushEvent('yasam', { action: newTitle });
      }
    },
  },
};
</script>
<style scoped lang="scss">
.content-card-list {
  margin: palette-space-level(20) 0;
  padding-left: palette-space-level(20);
  display: flex;
  flex-direction: column;
  .title-wrapper {
    display: flex;
    justify-content: space-between;
    margin-bottom: palette-space-level(30);
    &-inner {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      width: 50%;
      .input-wrapper {
        position: relative;
        width: 100%;
        border: 1px solid palette-color('grey-20');
        border-radius: palette-radius-level(2);
        height: 44px;
        padding: 12px;
        input {
          -webkit-appearance: none;
          border: none;
          outline: none;
          height: 100%;
          width: calc(100% - 25px);
        }
        input[type='search']::-webkit-search-decoration,
        input[type='search']::-webkit-search-cancel-button,
        input[type='search']::-webkit-search-results-button,
        input[type='search']::-webkit-search-results-decoration {
          display: none;
        }

        svg {
          position: absolute;
          right: 13px;
          height: 18px;
          width: 18px;
          top: 50%;
          transform: translateY(-50%);
        }
      }
      .icon-wrapper {
        display: flex;
        align-items: center;
        .icon-button {
          margin-left: 10px;
        }
      }
    }
  }
  .btn-more {
    margin-top: palette-space-level(30);
    border: 1px solid palette-color-level('grey', '20');
    align-self: center;
    background-color: palette-color-level('white', '100');
    border-radius: palette-radius-level(15);
    padding: palette-space-level(20) palette-space-level(50);
  }
  .list-container {
    display: grid;
    grid-template-columns: repeat(3, 321px);
    grid-gap: 20px;
    list-style: none;

    .content-card {
      border: 1px solid palette-color('grey-20');

      .media-box {
        position: relative;
        img {
          width: 100%;
        }
        .media-content {
          position: absolute;
          width: 100%;
          display: flex;
          align-items: center;
          flex-direction: row;
          justify-content: space-between;
          bottom: 10px;
          padding: 0 palette-space-level(10);
          .media-category {
            padding: 10px;
            box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.5);
            font-size: 12px;
            font-weight: 600;
          }
        }
      }
      .feed-content {
        padding: palette-space-level(20);

        p {
          margin-bottom: 5px;
        }
      }
    }
  }
}
.icon-button {
  border-radius: 4px;
  border: solid 1px #b4c2d3;
  height: 44px;
  width: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
}
.search-button {
  padding: 12px;
}

.icon-button,
.search-button,
.btn-more,
.search-icon {
  cursor: pointer;
}
.filter-button svg,
.search-button svg {
  pointer-events: none;
}

/deep/ .feed-media {
  cursor: pointer;
}
</style>
